import { A, cache, createAsync, useParams } from "@solidjs/router";
import { Accessor, For, Show, Suspense } from "solid-js";
import {
  giftIcon,
  novaChatBubbleBtm,
  novaChatBubbleTop,
  novaStar,
  userCircle,
} from "~/assets/assets";
import {
  getPlutoChatDetailsRouteData,
  PlutoChatDetailsRouteData,
} from "~/server/data/pluto_chat_id_route_data";
import { ChatHistory } from "~/server/types/pluto";
import { DottedLoader } from "~/widgets/loader";

const getPlutoChatDetailsRouteData$C = cache(
  getPlutoChatDetailsRouteData,
  "pluto_chat_details"
);

export default function ChatPage() {
  const params = useParams();

  const routeData: Accessor<PlutoChatDetailsRouteData | undefined> =
    createAsync(() => getPlutoChatDetailsRouteData$C(params.chatId), {
      deferStream: true,
    });

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <div class="m-auto flex h-full flex-col md:w-[800px]">
        <div class="flex-1 overflow-y-auto">
          <div class="space-y-4 p-4 pt-16">
            <For each={routeData()?.chatDetails?.messages}>
              {(message) => <MessageBubble message={message} />}
            </For>
            <Show when={routeData()?.giftDetails}>
              <div class="flex items-start gap-2">
                <img src={novaStar} alt="nova" class="h-8 w-8 rounded-full" />
                <div class="flex flex-col gap-1">
                  <div
                    class={`shadow-md relative max-w-[440px] rounded-[20px] 
                  rounded-tl-[4px] bg-plutoPurple
                  p-[18px] text-white`}
                  >
                    <img
                      src={novaChatBubbleTop}
                      class="absolute right-0 top-0 rounded-tr-[20px]"
                    />
                    <img
                      src={novaChatBubbleBtm}
                      class="absolute bottom-0 left-0 rounded-bl-[20px]"
                    />
                    <div class="flex flex-col gap-3 text-normal">
                      <p class="text-normal">
                        Here’s the gift for{" "}
                        {
                          routeData()?.giftDetails?.content.text.occasion
                            .recipientName
                        }
                        , hope they like it and you too.
                      </p>
                      <A
                        href={`/gifts/${routeData()?.giftDetails?.id}`}
                        class="z-30 flex h-[44px] w-[127px] cursor-pointer items-center justify-center gap-1 rounded-[41px] bg-baseTertiaryMedium px-3"
                      >
                        <img src={giftIcon} alt="gift icon" class="pb-1" />
                        <p class="text-buttonMedium font-bold text-textDark">
                          View gift
                        </p>
                      </A>
                    </div>
                  </div>
                </div>
              </div>
            </Show>
          </div>
        </div>

        <div class="bg-basePrimaryDark px-2.5 py-3 text-center">
          <p class="text-medium text-textNormal">
            This conversation has been marked as completed.
          </p>
          <p class="text-medium text-textNormal">
            <A href="/gifts/new" class="underline">
              Start a new conversation
            </A>
          </p>
        </div>
      </div>
    </Suspense>
  );
}

const MessageBubble = (props: { message: ChatHistory }) => (
  <div
    class={`flex ${props.message.sender === "user" ? "justify-end" : "justify-start"}`}
  >
    <div
      class={`flex gap-2 ${props.message.sender === "user" ? "flex-row-reverse items-end" : "items-start"}`}
    >
      <img
        src={props.message.sender === "user" ? userCircle : novaStar}
        alt={`Avatar of ${props.message.sender}`}
        class="h-8 w-8 rounded-full"
      />
      <div class="flex flex-col gap-2">
        <div
          class={`relative max-w-[440px] rounded-[20px] p-[18px] text-white ${
            props.message.sender === "user"
              ? "rounded-br-[4px] border border-[#252525] bg-black"
              : "rounded-tl-[4px] bg-plutoPurple"
          } shadow-md`}
        >
          <Show when={props.message.sender === "system"}>
            <img
              src={novaChatBubbleTop}
              class="absolute right-0 top-0 rounded-tr-[20px]"
            />
            <img
              src={novaChatBubbleBtm}
              class="absolute bottom-0 left-0 rounded-bl-[20px]"
            />
          </Show>
          <p class="text-normal">{props.message.content}</p>
        </div>
      </div>
    </div>
  </div>
);
