"use server";

import { getRequestEvent } from "solid-js/web";
import {
  getConversationById,
  getGiftByConversationId,
} from "../apis/server_apis";
import { ChatDetails, GiftCardDetails } from "../types/pluto";
import { getCookie } from "vinxi/http";
import { Cookie } from "~/types";
import { redirect } from "@solidjs/router";

export type PlutoChatDetailsRouteData = {
  chatDetails?: ChatDetails;
  giftDetails?: GiftCardDetails;
};

export const getPlutoChatDetailsRouteData = async (
  chatId: string
): Promise<PlutoChatDetailsRouteData> => {
  const requestEvent = getRequestEvent();
  let giftDetails: GiftCardDetails | undefined;
  let chatDetails: ChatDetails | undefined;

  if (requestEvent) {
    let sid =
      requestEvent?.locals?.sid ??
      getCookie(requestEvent?.nativeEvent, Cookie.SessionId);

    if (!sid) {
      throw redirect("/gifts/login");
    } else {
      chatDetails = await getConversationById(chatId);

      if (chatDetails.status === "COMPLETED") {
        giftDetails = await getGiftByConversationId(chatDetails.id);
      }
    }
  }

  return { chatDetails, giftDetails };
};
